/*!
 * froala_editor v2.8.5 (https://www.froala.com/wysiwyg-editor)
 * License https://froala.com/wysiwyg-editor/terms/
 * Copyright 2014-2018 Froala Labs
 */

(function (factory) {
    if (typeof define === 'function' && define.amd) {
        // AMD. Register as an anonymous module.
        define(['jquery'], factory);
    } else if (typeof module === 'object' && module.exports) {
        // Node/CommonJS
        module.exports = function( root, jQuery ) {
            if ( jQuery === undefined ) {
                // require('jQuery') returns a factory that requires window to
                // build a jQuery instance, we normalize how we use modules
                // that require this pattern but the window provided is a noop
                // if it's defined (how jquery works)
                if ( typeof window !== 'undefined' ) {
                    jQuery = require('jquery');
                }
                else {
                    jQuery = require('jquery')(root);
                }
            }
            return factory(jQuery);
        };
    } else {
        // Browser globals
        factory(window.jQuery);
    }
}(function ($) {
/**
 * Testing language
 */

$.FE.LANGUAGE['ft'] = {
  translation: {
    // Place holder
    "Type something": "FT_Type something",

    // Basic formatting
    "Bold": "FT_Bold",
    "Italic": "FT_Italic",
    "Underline": "FT_Underline",
    "Strikethrough": "FT_Strikethrough",

    // Main buttons
    "Insert": "FT_Insert",
    "Delete": "FT_Delete",
    "Cancel": "FT_Cancel",
    "OK": "FT_OK",
    "Back": "FT_Back",
    "Remove": "FT_Remove",
    "More": "FT_More",
    "Update": "FT_Update",
    "Style": "FT_Style",

    // Font
    "Font Family": "FT_Font Family",
    "Font Size": "FT_Font Size",

    // Colors
    "Colors": "FT_Colors",
    "Background": "FT_Background",
    "Text": "FT_Text",
    "HEX Color": "FT_HEX Colour",

    // Paragraphs
    "Paragraph Format": "FT_Paragraph Format",
    "Normal": "FT_Normal",
    "Code": "FT_Code",
    "Heading 1": "FT_Heading 1",
    "Heading 2": "FT_Heading 2",
    "Heading 3": "FT_Heading 3",
    "Heading 4": "FT_Heading 4",

    // Style
    "Paragraph Style": "FT_Paragraph Style",
    "Inline Style": "FT_Inline Style",

    // Alignment
    "Align": "FT_Align",
    "Align Left": "FT_Align Left",
    "Align Center": "FT_Align Center",
    "Align Right": "FT_Align Right",
    "Align Justify": "FT_Align Justify",
    "None": "FT_None",

    // Lists
    "Ordered List": "FT_Ordered List",
    "Unordered List": "FT_Unordered List",

    // Indent
    "Decrease Indent": "FT_Decrease Indent",
    "Increase Indent": "FT_Increase Indent",

    // Links
    "Insert Link": "FT_Insert Link",
    "Open in new tab": "FT_Open in new tab",
    "Open Link": "FT_Open Link",
    "Edit Link": "FT_Edit Link",
    "Unlink": "FT_Unlink",
    "Choose Link": "FT_Choose Link",

    // Images
    "Insert Image": "FT_Insert Image",
    "Upload Image": "FT_Upload Image",
    "By URL": "FT_By URL",
    "Browse": "FT_Browse",
    "Drop image": "FT_Drop image",
    "or click": "FT_or click",
    "Manage Images": "FT_Manage Images",
    "Loading": "FT_Loading",
    "Deleting": "FT_Deleting",
    "Tags": "FT_Tags",
    "Are you sure? Image will be deleted.": "FT_Are you sure? Image will be deleted.",
    "Replace": "FT_Replace",
    "Uploading": "FT_Uploading",
    "Loading image": "FT_Loading image",
    "Display": "FT_Display",
    "Inline": "FT_Inline",
    "Break Text": "FT_Break Text",
    "Alternative Text": "FT_Alternative Text",
    "Change Size": "FT_Change Size",
    "Width": "FT_Width",
    "Height": "FT_Height",
    "Something went wrong. Please try again.": "FT_Something went wrong. Please try again.",
    "Image Caption": "FT_Image Caption",
    "Advanced Edit": "FT_Advanced Edit",

    // Video
    "Insert Video": "FT_Insert Video",
    "Embedded Code": "FT_Embedded Code",
    "Paste in a video URL": "FT_Paste in a video URL",
    "Drop video": "FT_Drop video",
    "Your browser does not support HTML5 video.": "FT_Your browser does not support HTML5 video.",
    "Upload Video": "FT_Upload Video",

    // Tables
    "Insert Table": "FT_Insert Table",
    "Table Header": "FT_Table Header",
    "Remove Table": "FT_Remove Table",
    "Table Style": "FT_Table Style",
    "Horizontal Align": "FT_Horizontal Align",
    "Row": "FT_Row",
    "Insert row above": "FT_Insert row above",
    "Insert row below": "FT_Insert row below",
    "Delete row": "FT_Delete row",
    "Column": "FT_Column",
    "Insert column before": "FT_Insert column before",
    "Insert column after": "FT_Insert column after",
    "Delete column": "FT_Delete column",
    "Cell": "FT_Cell",
    "Merge cells": "FT_Merge cells",
    "Horizontal split": "FT_Horizontal split",
    "Vertical split": "FT_Vertical split",
    "Cell Background": "FT_Cell Background",
    "Vertical Align": "FT_Vertical Align",
    "Top": "FT_Top",
    "Middle": "FT_Middle",
    "Bottom": "FT_Bottom",
    "Align Top": "FT_Align Top",
    "Align Middle": "FT_Align Middle",
    "Align Bottom": "FT_Align Bottom",
    "Cell Style": "FT_Cell Style",

    // Files
    "Upload File": "FT_Upload File",
    "Drop file": "FT_Drop file",

    // Emoticons
    "Emoticons": "FT_Emoticons",

    // Line breaker
    "Break": "FT_Break",

    // Math
    "Subscript": "FT_Subscript",
    "Superscript": "FT_Superscript",

    // Full screen
    "Fullscreen": "FT_Fullscreen",

    // Horizontal line
    "Insert Horizontal Line": "FT_Insert Horizontal Line",

    // Clear formatting
    "Clear Formatting": "FT_Cell Formatting",

    // Undo, redo
    "Undo": "FT_Undo",
    "Redo": "FT_Redo",

    // Select all
    "Select All": "FT_Select All",

    // Code view
    "Code View": "FT_Code View",

    // Quote
    "Quote": "FT_Quote",
    "Increase": "FT_Increase",
    "Decrease": "FT_Decrease",

    // Quick Insert
    "Quick Insert": "FT_Quick Insert",

    // Spcial Characters
    "Special Characters": "FT_Special Characters",
    "Latin": "FT_Latin",
    "Greek": "FT_Greek",
    "Cyrillic": "FT_Cyrillic",
    "Punctuation": "FT_Punctuation",
    "Currency": "FT_Currency",
    "Arrows": "FT_Arrows",
    "Math": "FT_Math",
    "Misc": "FT_Misc",

    // Print.
    "Print": "FT_Print",

    // Spell Checker.
    "Spell Checker": "FT_Spell Checker",

    // Help
    "Help": "FT_Help",
    "Shortcuts": "FT_Shortcuts",
    "Inline Editor": "FT_Inline Editor",
    "Show the editor": "FT_Show the editor",
    "Common actions": "FT_Common actions",
    "Copy": "FT_Copy",
    "Cut": "FT_Cut",
    "Paste": "FT_Paste",
    "Basic Formatting": "FT_Basic Formatting",
    "Increase quote level": "FT_Increase quote level",
    "Decrease quote level": "FT_Decrease quote level",
    "Image / Video": "FT_Image / Video",
    "Resize larger": "FT_Resize larger",
    "Resize smaller": "FT_Resize smaller",
    "Table": "FT_Table",
    "Select table cell": "FT_Select table cell",
    "Extend selection one cell": "FT_Extend selection one cell",
    "Extend selection one row": "FT_Extend selection one row",
    "Navigation": "FT_Navigation",
    "Focus popup / toolbar": "FT_Focus popup / toolbar",
    "Return focus to previous position": "FT_Return focus to previous position",

    // Embed.ly
    "Embed URL": "FT_Embed URL",
    "Paste in a URL to embed": "FT_Paste in a URL to embed",

    // Word Paste.
    "The pasted content is coming from a Microsoft Word document. Do you want to keep the format or clean it up?": "FT_The pasted content is coming from a Microsoft Word document. Do you want to keep the format or clean it up?",
    "Keep": "FT_Keep",
    "Clean": "FT_Clean",
    "Word Paste Detected": "FT_Word Paste Detected"
  },
  direction: "ltr"
};

}));
